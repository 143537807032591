<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 数据管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>报警数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input
            clearable
            size="mini"
            v-model="companyNameOrMnInput"
            placeholder="企业名称 / MN号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择区域 </span>
          <el-select
            @change="handleAreaChange"
            size="mini"
            v-model="select.areaName"
            placeholder="全部区域"
          >
            <el-option
              v-for="item in options.areaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择企业级别 </span>
          <el-select
            @change="handleCompanyLevelChange"
            size="mini"
            v-model="select.companyLevel"
            placeholder="全部级别"
          >
            <el-option
              v-for="item in options.companyLevelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block">
          <span class="demonstration">选择企业类型 </span>
          <el-select
            @change="handleCompanyTypeChange"
            size="mini"
            v-model="select.companyType"
            placeholder="全部类型"
          >
            <el-option
              v-for="item in options.companyTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table
        :data="tableData"
        @select="handleSelectChange"
        @select-all="handleSelectChange"
        border
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column type="index" :index="1" label="序号">
        </el-table-column>
        <el-table-column prop="areaName" label="区域"> </el-table-column>
        <el-table-column prop="companyLevel" label="企业级别">
        </el-table-column>
        <el-table-column prop="companyType" label="企业类型"> </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="exceedPollutionName" label="超标污染物">
          <template #default="scope">
            <el-tag
              size="mini"
              type="warning"
              :key="item"
              v-for="item in scope.row.exceedPollutionName"
            >
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="latestAlarmTime" label="最新超标时间" sortable>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                @click="handleCheckData(scope.$index, scope.row)"
              >
                查看数据
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="pagination.currentPage"
          :page-sizes="[1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getTable } from "@/api/alert";
import { getAreaOptions } from "@/api/area";
import { getCompanyLevelOptions } from "@/api/company-level";
import { getCompanyTypeOptions } from "@/api/company-type";
import { exportAlert } from "@/api/export";
import router from "@/router";

export default {
  name: "Alert",
  data() {
    return {
      multipleSelection: [],
      companyNameOrMnInput: "",
      options: {
        areaOptions: [],
        companyLevelOptions: [],
        companyTypeOptions: [],
      },
      select: {
        areaName: "",
        companyLevel: "",
        companyType: "",
      },
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getOptions();
    this.getList();
  },
  methods: {
    handleSelectChange(val) {
      this.multipleSelection = val;
    },
    handleClear() {
      this.companyNameOrMnInput = "";
    },
    handleSearch() {
      console.log(this.companyNameOrMnInput);
      this.getList();
    },
    handleExport() {
      const mns = this.$_.map(this.multipleSelection, "mn");
      const requestBody = {
        beginDateTime: new Date().getTime(),
        endDateTime: new Date().getTime(),
        mns: mns,
      };
      exportAlert(requestBody);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleCheckData(index, row) {
      const companyName = row.companyName;
      const monitoryPointName = row.monitoryPointName;
      const latestAlarmTime = row.latestAlarmTime;
      router.push({
        path: "data-detail",
        query: {
          companyName: companyName,
          monitoryPointName: monitoryPointName,
          latestAlarmTime: new Date(latestAlarmTime).getTime(),
        },
      });
    },
    handleAreaChange(val) {
      this.select.areaName = val;
      this.getList();
    },
    handleCompanyLevelChange(val) {
      this.select.companyLevel = val;
      this.getList();
    },
    handleCompanyTypeChange(val) {
      this.select.companyType = val;
      this.getList();
    },
    getList() {
      const param = {
        companyNameOrMnInput: this.companyNameOrMnInput,
        areaName: this.select.areaName,
        companyLevel: this.select.companyLevel,
        companyType: this.select.companyType,
        // pageSize: this.pagination.pageSize,
        pageSize: 1000,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        console.log("请求的报警数据: ", JSON.stringify(data));
        // this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        data.list.forEach((item) => {
          item.exceedPollutionName = JSON.parse(item.exceedPollutionName);
        });
        this.tableData = data.list;
        this.getTotalSize();
      });
    },
    getTotalSize() {
      const param = {
        companyNameOrMnInput: this.companyNameOrMnInput,
        areaName: this.select.areaName,
        companyLevel: this.select.companyLevel,
        companyType: this.select.companyType,
        pageSize: 2000,
        currentPage: this.pagination.currentPage,
      };
      getTable(param).then((result) => {
        const data = result.data;
        this.pagination.total = data.total;
      });
    },
    // 获取区域名称options
    // 获取公司级别options
    // 获取公司类型options
    getOptions() {
      getAreaOptions().then((result) => {
        this.options.areaOptions = result.data;
        this.options.areaOptions.unshift({ value: "", label: "全部" });
      });
      getCompanyLevelOptions().then((result) => {
        this.options.companyLevelOptions = result.data;
        this.options.companyLevelOptions.unshift({ value: "", label: "全部" });
      });
      getCompanyTypeOptions().then((result) => {
        this.options.companyTypeOptions = result.data;
        this.options.companyTypeOptions.unshift({ value: "", label: "全部" });
      });
    },
  },
};
</script>

<style scoped></style>
